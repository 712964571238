/********** Template CSS **********/
:root {
    --primary: #FEA116;
    --light: #F1F8FF;
    --dark: #0F172B;
}

body {
    position: relative;
    max-width: 700px;
    margin: 0 auto;
    font-family: "Roboto", sans-serif;
  }

/* Ensure the container div (image-item) fills the space */
.image-item {
    display: flex;              /* Flexbox container to allow flexibility */
    justify-content: center;    /* Horizontally center the image */
    align-items: center;        /* Vertically center the image */
    overflow: hidden;           /* Hide overflow if the image exceeds the div */
    height: 100%;               /* Make the div take full height */
  }
  
  /* Make the image take up full space within its container */
  .full-height-image {
    object-fit: cover;   /* Make the image cover the div while maintaining its aspect ratio */
    width: 100%;          /* Fill the width of the container */
    height: 100%;         /* Fill the height of the container */
  }
  
  /* Ensure that OwlCarousel handles responsive sizing correctly */
  .owl-theme .image-item {
    height: 100%;  /* Allow the image item to take up the full height of the carousel */
  }
  
  /* Optional: make sure images don't distort when in smaller viewports */
  @media (max-width: 768px) {
    .image-item {
      height: 300px; /* Set a fixed height for mobile if necessary */
    }
  }

  body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/usa-phil-flag.jpg');
    opacity: 0.15;
    z-index: -1;
}

.landing-page-logo {
    margin: -10% 0% -35% 0%;
}

.ff-secondary {
    font-family: 'Pacifico', cursive;
}

.fw-medium {
    font-weight: 600 !important;
}

.fw-semi-bold {
    font-weight: 700 !important;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    transition: .5s;
}

.btn.btn-primary,
.btn.btn-secondary {
    color: #FFFFFF;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 2px;
}


/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
    position: relative;
    margin-left: 25px;
    padding: 35px 0;
    font-size: 15px;
    color: var(--light) !important;
    text-transform: uppercase;
    font-weight: 500;
    outline: none;
    transition: .5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 20px 0;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: var(--primary) !important;
}

.navbar-dark .navbar-brand img {
    max-height: 60px;
    transition: .5s;
}

.sticky-top.navbar-dark .navbar-brand img {
    max-height: 45px;
}

@media (max-width: 991.98px) {
    .sticky-top.navbar-dark {
        position: relative;
    }

    .navbar-dark .navbar-collapse {
        margin-top: 15px;
        border-top: 1px solid rgba(255, 255, 255, .1)
    }

    .navbar-dark .navbar-nav .nav-link,
    .sticky-top.navbar-dark .navbar-nav .nav-link {
        padding: 10px 0;
        margin-left: 0;
    }

    .navbar-dark .navbar-brand img {
        max-height: 45px;
    }
}

@media (min-width: 992px) {
    .navbar-dark {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 999;
        background: transparent !important;
    }

    .sticky-top.navbar-dark {
        position: fixed;
        background: var(--dark) !important;
    }
}

@media (max-width: 424px) {
    .row.g-5.align-items-center {
        --bs-gutter-y: -7rem !important;
    }
}

@media (min-width: 425px) {
    .row.g-5.align-items-center {
        --bs-gutter-y: -9rem !important;
    }
}

@media (max-width: 990px) {
    .hamburger-icon {
        position: absolute;
        left: 90%;
    }
}

@media (max-width: 500px) {
    .hamburger-icon {
        position: absolute;
        left: 85%;
    }
}

.food-truck-icon {
    height: 60px;
    width: 60px;
    background-color: gold;
    opacity: 0.8;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: contain;
}


/* Wrapper to make the iframe container responsive */
.iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;  /* Ensure the container takes the full width */
  height: 500px;
  box-sizing: border-box;
}

/* Make the iframe responsive */
.responsive-iframe {
  width: 100%;
  max-width: 500px;  /* Limit the maximum width */
  height: 100%;
  aspect-ratio: 500 / 450;
  border: none;
  overflow: hidden;
}

/* Media query for smaller screens (mobile) */
@media (max-width: 768px) {
  .responsive-iframe {
    width: 100%;  /* Make iframe a bit smaller on mobile */
    max-width: 400px;  /* Limit max width on mobile devices */
    height: 100%;
  }
  .iframe-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;  /* Ensure the container takes the full width */
    height: 420px;
    box-sizing: border-box;
  }
}

@keyframes imgRotate { 
    100% { 
        transform: rotate(360deg); 
    } 
}

.breadcrumb-item + .breadcrumb-item::before {
    color: rgba(255, 255, 255, .5);
}


/*** Section Title ***/
.section-title {
    position: relative;
    display: inline-block;
    text-align: center;
}

.section-title::before {
    position: relative;
    content: "";
    width: 45px;
    height: 2px;
    top: 50%;
    left: -55px;
    margin-top: -1px;
    background: var(--primary);
}

.section-title::after {
    position: relative;
    content: "";
    width: 45px;
    height: 2px;
    top: 50%;
    right: -55px;
    margin-top: -1px;
    background: var(--primary);
}

.section-title.text-start::before,
.section-title.text-end::after {
    display: none;
}


/*** Service ***/
.service-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
    transition: .5s;
}

.service-item:hover {
    background: var(--primary);
}

.service-item * {
    transition: .5s;
}

.service-item:hover * {
    color: var(--light) !important;
}


/*** Food Menu ***/
.nav-pills .nav-item .active {
    border-bottom: 2px solid var(--primary);
}


@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}


/*** Team ***/
.team-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
    height: calc(100% - 38px);
    transition: .5s;
}

.team-item img {
    transition: .5s;
}

.team-item:hover img {
    transform: scale(1.1);
}

.team-item:hover {
    height: 100%;
}

.team-item .btn {
    border-radius: 38px 38px 0 0;
}


/*** Reviews ***/
.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
    background: var(--primary) !important;
    border-color: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item * {
    color: var(--light) !important;
}

.testimonial-carousel .owl-dots {
    margin-top: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    /* width: 15px; */
    /* height: 15px; */
    border: 1px solid #CCCCCC;
    border-radius: 15px;
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    background: var(--primary);
    border-color: var(--primary);
}


/*** Footer ***/
.footer .btn.btn-social {
    margin-right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    border: 1px solid #FFFFFF;
    border-radius: 35px;
    transition: .3s;
}

.footer .btn.btn-social:hover {
    color: var(--primary);
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .footer-menu a {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}

/* location cards styling */
.location-card {
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
}

.location-card img {
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
}

.location-card h2 {
    font-size: 24px;
    color: #b80000;
    margin-bottom: 10px;
}

.location-card p {
    font-size: 16px;
    color: #666;
    margin-bottom: 5px;
}

/* Book us form styling */
.booking-form {
    max-width: 600px;
    margin: 0 auto;
}

.booking-form .form-floating {
    margin-bottom: 20px;
}

.booking-form .btn-primary {
    font-size: 1.2rem;
    padding: 12px 20px;
}

.form-container {
    position: relative;
    width: 93%;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    background-color: #ffffff;
}

.booking-form .form-floating {
    margin-bottom: 20px;
}

.booking-form .btn-primary {
    font-size: 1.2rem;
    padding: 12px 20px;
}

.after-booking-form-submit {
    margin-top: 20px;
    margin-bottom: 20px;
}

.confirmation-message {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    font-size: 1.1em;
  }
  .error-message {
    background-color: #d30000;
    color: rgba(255, 255, 255, 0);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    font-size: 1.1em;
  }

  .calendar-container {
    position: relative;
    padding-bottom: 115%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #ffffff;
  }
  
  .calendar-iframe {
    position: absolute;
    top: 0;
    left: 1%;
    width: 98%;
    height: 98%;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .7);
  }

  .iframe-container {
    text-align: center;
  }
  
  .centered-iframe {
    margin: 0 auto;
  }

  .suggestion-box {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 3;
  }
  
  .suggestion-item {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  
  .loading-animation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    z-index: 999; /* Ensure it's above the form */
  }
  
  .loading-animation::after {
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid #333; /* Gray border */
    border-top-color: transparent; /* Transparent top border */
    animation: spin 1s linear infinite; /* Spin animation */
  }
  
  .loading-text {
    margin-top: 10px;
    padding: 5%;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.card-container {
perspective: 1000px;
}

.landing-page-logo {
transition: transform 0.6s;
transform-style: preserve-3d;
}

.landing-page-logo.flipped {
transform: rotateY(360deg);
}

.card-container {
  position: relative;
  perspective: 1000px;
  cursor: pointer;
}

.card-side {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  transition: transform 0.6s;
}

.card-side.front {
  transform: rotateY(0deg);
}

.card-side.back {
  transform: rotateY(180deg);
}

.card-container.flipped .card-side.front {
  transform: rotateY(-180deg);
}

.card-container.flipped .card-side.back {
  transform: rotateY(0deg);
}
.icon {
    transition: opacity 1s ease-in-out; /* Duration of the fade-in effect */
    opacity: 1;
  }
  
  .icon.fade-in {
    opacity: 0;
    animation: fadeIn 1s forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .about-images {
    display: block;
    width: 100%;
    height: 250px;
    max-width: 100%;
    margin: 10px;
    border: 1px solid rgb(119, 119, 119);
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.45);
    transition: transform 0.3s;
  }